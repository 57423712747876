html {
	scroll-behavior: smooth;
}

#root {
	width: 100%;
	height: 100%;
}

body {
	font-size: 1rem;
	font-weight: 300;
	color: #3D516F;
	background-color: #f2f2f2;
}

.navbar {
	justify-content: space-between;
}

.navbar .logo-link {
	padding: 0;
}

.navbar .logo-link img {
	width: 4rem;
	height: 4rem;
}

.navbar .logo-link .logo-title {
	color: #3D516F;
	font-weight: 600;
	text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.5);
	line-height: 100%;
	position: relative;
}

@media (max-width: 576px) {
	.navbar .logo-link .logo-title {
	}
}

.navbar .navbar-toggler {
	border: 0;
}

@media (max-width: 576px) {
	.search-bar {
		margin-top: 1rem !important;
	}
}
