@font-face {
	font-family: "Berlin Sans FB";
	src: url("BerlinSansFBMince.eot");
	src: url("BerlinSansFBMince.eot?#iefix") format("embedded-opentype"),
	url("BerlinSansFBMince.woff2") format("woff2"),
	url("BerlinSansFBMince.woff") format("woff"),
	url("BerlinSansFBMince.ttf") format("truetype"),
	url("BerlinSansFBMince.svg#BerlinSansFBMince") format("svg");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Berlin Sans FB";
	src: url("BerlinSansFB-Reg.eot");
	src: url("BerlinSansFB-Reg.eot?#iefix") format("embedded-opentype"),
	url("BerlinSansFB-Reg.woff2") format("woff2"),
	url("BerlinSansFB-Reg.woff") format("woff"),
	url("BerlinSansFB-Reg.ttf") format("truetype"),
	url("BerlinSansFB-Reg.svg#BerlinSansFB-Reg") format("svg");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Berlin Sans FB";
	src: url("BerlinSansFBDemi-Bold.eot");
	src: url("BerlinSansFBDemi-Bold.eot?#iefix") format("embedded-opentype"),
	url("BerlinSansFBDemi-Bold.woff2") format("woff2"),
	url("BerlinSansFBDemi-Bold.woff") format("woff"),
	url("BerlinSansFBDemi-Bold.ttf") format("truetype"),
	url("BerlinSansFBDemi-Bold.svg#BerlinSansFBDemi-Bold") format("svg");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Berlin Sans FB";
	src: url("BerlinSansFB-Bold.eot");
	src: url("BerlinSansFB-Bold.eot?#iefix") format("embedded-opentype"),
	url("BerlinSansFB-Bold.woff2") format("woff2"),
	url("BerlinSansFB-Bold.woff") format("woff"),
	url("BerlinSansFB-Bold.ttf") format("truetype"),
	url("BerlinSansFB-Bold.svg#BerlinSansFB-Bold") format("svg");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "play24";
	src: url("./play24.eot?52051241");
	src: url("./play24.eot?52051241#iefix") format("embedded-opentype"),
	url("./play24.woff2?52051241") format("woff2"),
	url("./play24.woff?52051241") format("woff"),
	url("./play24.ttf?52051241") format("truetype"),
	url("./play24.svg?52051241#play24") format("svg");
	font-weight: normal;
	font-style: normal;
}

.icon-play24-logo:before {
	content: "\e800";
}

.play24-icon {
	display: inline;
	font-family: "play24", serif;
	font-style: normal;
	font-weight: normal;
	vertical-align: middle;
	speak: none;
	text-decoration: inherit;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-shadow: 0 2px 1.5px #0a040345;
}