.app {
	text-align: center;
}

.main-content {

}

.footer-menu {
	width: 100%;
	max-width: 1068px;
	color: #013440;
	z-index: 1001;
}

.footer-menu .navbar {
	background: linear-gradient(0deg, #f2f2f2, #f2f2f2);
	box-shadow: 4px 2px 16px rgba(136, 165, 191, 0.48), -4px -2px 16px #ffffff;
	justify-content: space-between;
}

.footer-menu .list-group-item {
	border: none;
	background: transparent;
	text-align: left;
	position: relative;
}

.footer-menu .list-group-item h5 {
	color: #013440;
}

.footer-menu .list-group-item .text-moving {

}

.footer-menu .curved-copyright-header {
	margin-top: 3rem;
}

.footer-menu .curved-copyright-text {
	margin-top: 2rem;
}

.footer-menu .list-group-item-heading {
	margin-top: 3rem;
}

.footer-menu .list-group-item .logo-title {
	font-weight: 600;
	position: relative;
	top: -0.2rem;
	color: #013440;
}

.footer-menu .contact-form {
	width: 100%;
}

.footer-menu .contact-form button {
	font-size: 80%;
}

@media only screen and (max-width: 734px) {
	.footer-menu .contact-form {
		width: 75%;
	}
}

.mm-container {

}

.mm-menu {
	display: flex;
	flex-direction: row;

	box-shadow: none !important;

	top: 0;
	left: 0;
	z-index: 2;
}

.mm-menu .nav-link {
	font-size: 1rem;
	font-weight: 700;
}

.mm-menu .nav-link.network-toggle {
	padding: 0.5rem 0.75rem;
}

.mm-menu .nav-link.active {
	color: #3D516F !important;
}

.ancillary-menus {
	display: flex;
	flex-direction: row;
}

.ancillary-menus button:focus {
	outline: none;
	border: none;
}

.ancillary-menus button:focus:not(:active) {
	box-shadow: none;
}

.ancillary-menus > .card {
	margin-right: 0.75rem;
	box-shadow: none;
}

.network-menus,
.mini-wallet,
.navigation-menus {
	height: 2.5rem;
	border-radius: 1.125rem;
}

.network-menus .card-body,
.mini-wallet .card-body,
.navigation-menus .card-body {
	height: 2.5rem;
	padding: 1rem;
}

.mini-wallet {
	border: none;
	background: none;
}

.mini-wallet .card-body {
	padding: 0.125rem;
}

.mini-wallet .card-body .address-container {
	position: relative;
	height: 100%;
	background-color: #F7F8FA;
	border-radius: 1rem;
}

.mini-wallet .wallet-container {
	height: 100%;
}

.mini-wallet .wallet-container .balance {
	font-weight: 700;
}

.mini-wallet .address-container span {
	font-weight: 600;
	line-height: 1rem;
}

.mini-wallet .address-container .address-avatar {
	position: relative;
	margin-left: 0.25rem;
	margin-top: -3%;
	overflow: hidden;
	border-radius: 50%;
	background-color: #cef8ff;
}

.mini-wallet .address-container img {
	height: 100%;
}

.navigation-menus {
	border: 1px solid #edededa6;
	border-radius: 1rem;
	background-image: -webkit-radial-gradient(10px 10px, circle cover, #f2f2f280, #ffffff80);
	background-color: transparent;

	min-height: 2.5rem;
	min-width: 2.5rem;

}

.mm-navigation {
	display: flex;
	min-height: 2.5rem;
	min-width: 2.5rem;

	justify-content: center;
	align-items: center;
}

.mm-navigation i {
	color: rgba(0, 0, 0, 0.5);
}

.network-menus .selected-network img {
	height: 1.5rem;
	margin-right: 0.25rem;
	margin-top: -1px;
}

@media (max-width: 576px) {
	.mm-container {

	}

	.ancillary-menus > .card {
		margin-right: 0.5rem !important;
	}

	.mini-wallet .card-body .address-container {
		width: 100%;
	}

	.mini-wallet .wallet-container .balance {
		display: none !important;
	}

	.network-menus .selected-network .network-name {
		display: none;
	}

	.network-menus .selected-network img {
		margin-right: 0;
	}
}

.ancillary-menus .network-menu {
	position: relative;
	min-width: 18rem;
	padding: 1rem;
	border-radius: 0.75rem;
	box-shadow: 0 2px 0 rgb(90 97 105 / 11%),
	0 4px 8px rgb(90 97 105 / 12%),
	0 10px 10px rgb(90 97 105 / 6%),
	0 7px 70px rgb(90 97 105 / 10%);
}

.ancillary-menus .network-menu .network-item {
	cursor: pointer;
}

.ancillary-menus .network-menu .network-item:not(:first-child),
.ancillary-menus .network-menu .network-item:not(:last-child) {
	margin: 0.625rem 0;
}

.network-menu img.network-logo {
	height: 2rem;
}

.ancillary-menus .network-menu .active-network {
	background-color: #ececf6;
	border-radius: 0.75rem;
}

.ancillary-menus .network-menu .active-network img {
	height: 1rem;
}

.active-network .interesting-link {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 0.5rem;
	width: 100%;
	font-size: 85%;
	font-weight: 600;
	color: #0f6674;
}

.active-network .interesting-link span:hover {
	text-decoration: underline;
}

.active-network .interesting-link i {
	margin-top: 0.125rem;
}

.network-menu .network-selection img {
	margin-right: 0.375rem;
}

.community-menu {
	flex-direction: column;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.75rem;
	background-color: rgba(255, 255, 255, 0.75) !important;
	background-clip: border-box;
	min-width: 5rem;

	word-wrap: break-word;

	position: absolute;
	width: 12rem;
	left: -9.5rem;

	box-shadow: 0 2px 0 rgb(90 97 105 / 11%),
	0 4px 8px rgb(90 97 105 / 12%),
	0 10px 10px rgb(90 97 105 / 6%),
	0 7px 70px rgb(90 97 105 / 10%);
}

.community-menu .dropdown-item {
	padding: 0;
}

.community-menu .dropdown-item:hover {
	background-color: transparent !important;
	color: #3D516F;
}

.loading-container {
	min-height: 1rem;
}

.loading-ripple {
	display: inline-block;
	position: relative;
	width: 7rem;
	height: 7rem;
}

.loading-ripple div {
	position: absolute;
	border: 4px solid #3D516F;
	opacity: 1;
	border-radius: 50%;
	animation: loading-ripple 1.25s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading-ripple div:nth-child(2) {
	animation-delay: -0.5s;
}

@keyframes loading-ripple {
	0% {
		top: 45%;
		left: 45%;
		width: 0;
		height: 0;
		opacity: 1;
	}

	100% {
		top: 0;
		left: 0;
		width: 90%;
		height: 90%;
		opacity: 0;
	}
}

div#loading-text {
	position: absolute;
	margin: 0 auto;
	top: 30%;
	height: 700px;
	user-select: none !important;
}

div#loading-text span {
	position: relative;
	font-size: 2rem;
	font-weight: bolder;
	color: inherit;
}

@-webkit-keyframes loading-animation {
	0% {
		transform: rotate(0deg) scale(1.5);
		color: #aaa;
	}

	10% {
		transform: rotate(0deg) scale(1.7);
		color: #ccc;
	}

	100% {
		transform: rotate(0deg) scale(1.2);
		color: transparent;
		text-shadow: #666 0 0 10px;
	}
}

div#loading-text div {
	position: relative;
	display: inline-block;
	-webkit-animation: loading-animation 2.2s infinite;
}

div#loading-text div.letter_container_1 {
	-webkit-animation-delay: 0s;
}

div#loading-text div.letter_container_2 {
	-webkit-animation-delay: .2s;
}

div#loading-text div.letter_container_3 {
	-webkit-animation-delay: .4s;
}

div#loading-text div.letter_container_4 {
	-webkit-animation-delay: .6s;
}

div#loading-text div.letter_container_5 {
	-webkit-animation-delay: .8s;
}

div#loading-text div.letter_container_6 {
	-webkit-animation-delay: 1s;
}

div#loading-text div.letter_container_7 {
	-webkit-animation-delay: 1.2s;
}

.glass {
	user-select: none;
	background-color: rgba(255, 255, 255, 0.45);
	backdrop-filter: blur(5px);
	border: 1px solid #ededed29;
	border-radius: 1rem;
	box-shadow: inset 0 0 10px rgb(0 0 0 / 20%);
	filter: drop-shadow(3px 3px 3px rgba(100, 105, 90, 0.3));
}

.glass:after {
	/*content: " ";*/
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;

	width: calc(100% - 1rem);
	height: calc(100% - 1rem);
	margin: 0.5rem;

	/* backdrop-filter: blur(5px); */
	border: 1px solid #ededed29;
	border-radius: 1.5rem;

	box-shadow: inset 0 0 10px rgb(0 0 0 / 20%);
	filter: drop-shadow(1px 1px 1px rgba(100, 105, 90, 0.3));
}

@keyframes float {
	0% {
		transform: translatey(0);
	}
	50% {
		transform: translatey(-0.95rem);
	}
	100% {
		transform: translatey(0);
	}
}